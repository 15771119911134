import {createStore} from 'redux';

const initialstate = {
    infoUser: 0,
    idedf:0,
    idcc:0,
};

const reducer = (state = initialstate, action) => {

    if (action.type === "INFO_USUARIO") {
        return {
            ...state,
            infoUser: action.infoUser
        };
    }
    if (action.type === "INFO_EDF") {
        return {
            ...state,
            idedf: action.idedf
        };
    }
    if (action.type === "INFO_CC") {
        return {
            ...state,
            idcc: action.idcc
        };
    }
    console.log(action);
    return state;

};


export default createStore(reducer);
